import React from "react"
import { graphql } from "gatsby";
import Layout from "../../component/layout";
import PostTeaser from "../../component/post.teaser"
import Meta from "../../component/meta"

export default function EnIndex ({data, location}) {
  let lang = location.pathname.split('/')[1];
  return (
    <React.Fragment>
    <Meta
      title="Roman Guivan: My personal homepage (aka Scrapyard of all the things i wanted to share with the world)"
      lang="en"
      keywords={[]}></Meta>
  <Layout language={lang}>
    <h1>Scrapyard of all the things i wanted to share with the world</h1>
    { data.allMarkdownRemark.edges.map(edge => (
        <PostTeaser key={edge.node.id} post={edge.node} lang={lang}>
        </PostTeaser>
    ))
    }
    </Layout>
    </React.Fragment>)
}

export const query = graphql`
{
  allMarkdownRemark(
    filter: { frontmatter: { lang: { eq: "en" } } },
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    totalCount
 		edges {
        node {
          id,
          fields {
              slug
          }
          frontmatter {
            title,
            tags,
            date(formatString: "MMMM DD, YYYY", locale: "en")
          },
          excerpt
        }
      }
  }
}
`